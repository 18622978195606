import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
const tennessee = require("../../images/tennessee.png")
const austinLogo = require("../../images/austin.png")
const kaistLogo = require("../../images/kaist.png")
const washingtonLogo = require("../../images/washington.png")
const floridaLogo = require("../../images/florida.png")
const hpe = require("../../images/hpe.png")
const intel = require("../../images/intel.png")
const dell = require("../../images/dell.png")
const amd = require("../../images/amd.png")

const PartnersPage = () => {
  const [highlighted, setHighlighted] = useState(0)

  useEffect(() => {
    let timeOut: any

    function blinkEffect() {
      timeOut = setTimeout(() => {
        if (highlighted === 8) setHighlighted(0)
        else setHighlighted(highlighted + 1)
      }, 1800)
    }

    blinkEffect()

    return function cleanup() {
      clearTimeout(timeOut)
    }
  }, [highlighted])

  return (
    <Layout>
      <SEO title="Partners" description="" lang="en" meta={[]} />
      <section className="full-hero partners">
        {/* Partner Listing */}

        <h1 className="text-center mb-3 mt-5">Flapmax Partners</h1>

        <div className="d-flex justify-content-center mb-5">
          <Link to="/partners/join">
            <Button size="lg" className="hero-button primary">
              Become a Partner
            </Button>
          </Link>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div
              className={`col-lg-3 partner-block ${
                highlighted === 0 ? "highlighted" : ""
              }`}
            >
              <img src={intel} alt="" />
            </div>
            <div
              className={`col-lg-3 partner-block ${
                highlighted === 1 ? "highlighted" : ""
              }`}
            >
              <img src={amd} alt="" />
            </div>
            <div
              className={`col-lg-3 partner-block ${
                highlighted === 2 ? "highlighted" : ""
              }`}
            >
              <img src={hpe} alt="" />
            </div>
            <div
              className={`col-lg-3 partner-block ${
                highlighted === 3 ? "highlighted" : ""
              }`}
            >
              <img src={dell} alt="" />
            </div>
          </div>
          <div className="row">
            <div
              className={`col-lg partner-block alt-bg ${
                highlighted === 4 ? "highlighted" : ""
              }`}
            >
              <img src={tennessee} alt="" />
            </div>
            <div
              className={`col-lg partner-block alt-bg ${
                highlighted === 5 ? "highlighted" : ""
              }`}
            >
              <img src={washingtonLogo} alt="" />
            </div>
            <div
              className={`col-lg partner-block alt-bg ${
                highlighted === 6 ? "highlighted" : ""
              }`}
            >
              <img src={austinLogo} alt="" />
            </div>
            <div
              className={`col-lg partner-block alt-bg ${
                highlighted === 7 ? "highlighted" : ""
              }`}
            >
              <img src={kaistLogo} alt="" />
            </div>
            <div
              className={`col-lg partner-block alt-bg ${
                highlighted === 8 ? "highlighted" : ""
              }`}
            >
              <img src={floridaLogo} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* Context Section and Internal Links */}
      <section className="bg-darkened">
        <div className="container">
          <h2 className="mb-2">
            Flapmax is Partnering with leaders from both Industry and Academia
          </h2>
          <p>
            Our highest priority is delivering fresh innovations in tech to
            drive civilization forward in as quick and meaningful a way as
            possible. In order to accomplish this we are partnering with the
            likes of Intel, AMD, universities ranging from Washington State to
            the University of Florida, and many more.
          </p>
          <div className="row d-flex justify-content-around pt-5">
            <div className="col-lg-5 card">
              <div className="d-flex">
                <h3 className="underline">Industry Partnership</h3>
              </div>
              <p>
                Flapmax strives to make Artificial Intelligence technology
                broadly available and easy-to-use for everyone. By leveraging
                support from other leaders in tech as well as our talented
                engineers we are making it happen.
              </p>
              <div>
                <Button as={Link} to="/partners/industry">
                  Learn More
                </Button>
              </div>
            </div>

            <div className="col-lg-5 card">
              <div className="d-flex">
                <h3 className="underline">Academic Collaboration</h3>
              </div>
              <p>
                Flapmax is driven by the desire to use AI and ML for good. With
                an already unique platform, we want to continue working with
                bright minds in tech to deliver on our mission and push even
                further.
              </p>
              <div>
                <Button as={Link} to="/partners/academic">
                  Learn more
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PartnersPage
